import { Component } from "react";
import { GrPhone, GrShieldSecurity, GrWebcam } from "react-icons/gr";
import { BsCodeSlash, BsCompass, BsDice2, BsGripHorizontal, BsHeadset, BsLaptop, BsPhone } from "react-icons/bs";

class ServicesComp extends Component {
  render() {
    return (
      <div className="services__servicesComp">
        <div>
          <h1>Our Services </h1>
          <button className="btn btn-primary">Get a Quote</button>
        </div>
        <div>
          <div>
            <div className="icon">
              <BsLaptop />
            </div>
            <h4>Software Development</h4>
            <p></p>
          </div>
          <div>
            <div className="icon">
              <BsCodeSlash />
            </div>
            <h4>Web Development</h4>
            <p></p>
          </div>
          <div>
            <div className="icon">
              <BsPhone />
            </div>
            <h4>Mobile App Development</h4>
            <p></p>
          </div>
          <div>
            <div className="icon">
              <BsHeadset />
            </div>
            <h4>Tech Consultancy</h4>
            <p></p>
          </div>
          <div>
            <div className="icon">
              <GrShieldSecurity />
            </div>
            <h4>Blockchain Development</h4>
            <p></p>
          </div>
          <div>
            <div className="icon">
              <BsDice2 />
            </div>
            <h4>UX/UI Design</h4>
            <p></p>
          </div>
          <div>
            <div className="icon">
              <BsCompass />
            </div>
            <h4>Graphics Design</h4>
            <p></p>
          </div>
          <div>
            <div className="icon">
              <BsGripHorizontal />
            </div>
            <h4>Technical Writing</h4>
            <p></p>
          </div>
        </div>
      </div>
    );
  }
}

export default ServicesComp;
