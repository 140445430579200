import { Component } from "react";
import Work from "./Work_box";
import "./style.css";
class Portfolio extends Component {
  constructor() {
    super();
    this.works = [
      {
        img: "https://i.postimg.cc/zDnHzdV2/Screenshot-2023-11-08-092338.png",
        title: "Sotechy.shop",
        text:
          "A Digitally transformed e-commerce platform majored on the sales of Electronic Gadget."
      },
      {
        img:
          "https://i.postimg.cc/MTJPBPx3/Screenshot-2023-11-08-093016.png",
        title: "MSME",
        text:
          "Ebonyi State Government seeks to expand on its drive to support the growth and development of businesses in the State."
      },
      {
        img:
          "https://i.postimg.cc/rp7zcHxm/Screenshot-2023-11-08-093806.png",
        title: "Pontys.io",
        text:
          "Pontys is your go to platform for Web3 services (education, skill, acquistion, agency , marketing) This Platform was Created together with the Team at Pontys Labs "
      },
      {
        img:
          "https://i.postimg.cc/QxsMm1jF/Screenshot-2023-11-08-094543.png",
        title: "The Royal Modern Hotels",
        text:
          "The Royal Modern Hotel is a luxurious hotel located in the heart of the city of Oghara Delta State."
      }
    ];
  }
  render() {
    return (
      <section className="container container__portfolio" id="portfolio">
        {/* <div className="portfolio__header">
          <h1>See Our Works Or Portfolio</h1>
          <button className="btn btn-primary">More About</button>
        </div> */}
        {this.works.map((work, index) => {
          if (index == 1) {
            return (
              <>
                <div className="portfolio__header">
                  <h1>Feautured Jobs, Send us a DM to see more</h1>
            
                </div>
                <Work img={work.img} title={work.title} text={work.text} />
              </>
            );
          }
          return <Work img={work.img} title={work.title} text={work.text} />;
        })}
      </section>
    );
  }
}
export default Portfolio;
