import { Component } from "react";

class Logo extends Component {
  render() {
    return (
      <div className="logo">
        <h4>SOTECHY LABS</h4> 
      </div>
    );
  }
}
export default Logo;
