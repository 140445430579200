import { Component } from "react";
import { CgInstagram } from "react-icons/cg";
import { AiFillTwitterSquare } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";

class Socials extends Component {
  render() {
    return (
      <div className="socials">
      
      </div>
    );
  }
}
export default Socials;
