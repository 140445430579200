import { Component } from "react";
import { CgInstagram } from "react-icons/cg";
import { AiFillTwitterSquare } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import "./style.css";
class Footer extends Component {
  render() {
    return (
      <footer id="contact" className="container footer__container">
        <div className="about">
          <h3>SOTECHY LABS</h3>
          <p className="text">
           Sotechy Labs is an innovative technology company specializing in software and web development,
           mobile app development, AI, blockchain, UX/UI design, digital marketing, and consulting 
           services.
          </p>
          <ul className="footer__socials">
            <li>
              <a href="#">
                <AiFillFacebook />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/sotechy_labs/">
                <CgInstagram />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/SotechyLabs">
                <AiFillTwitterSquare />
              </a>
            </li>
          </ul>
        </div>
        <div className="services">
          <h3>Services</h3>
          <ul>
            <li>
              <a href="#" className="text">
              Software Development
              </a>
            </li>
            <li>
              <a href="#" className="text">
              Blockchain Development
              </a>
            </li>
            <li>
              <a href="#" className="text">
              Tech Consultancy
              </a>
            </li>
          </ul>
        </div>
        <div className="contact">
          <h3>Contact</h3>
          <ul>
            <li>
              <a href="https://www.google.com/maps/dir//20+Etete+Road+Oka+300102+Benin+City/@6.2979282,5.6253888,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x1040d3e74dc8d16f:0xb66f02d93bc0a1f1" className="text">
                No 20A Etete Road GRA Benin City, Edo State Nigeria
              </a>
            </li>
            <li>
              <a href="tel:+2348165468975" className="text">
                Tel : +2348165468975
              </a>
            </li>
            <li>
            <a href="mailto:admin@sotechylabs.xyz" className="text">
                Email: admin@sotechylabs.xyz
              </a>
            </li>
          </ul>
        </div>
      </footer>
    );
  }
}

export default Footer;
