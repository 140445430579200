import { Component } from "react";
import { BiBrain } from "react-icons/bi";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

class Intro extends Component {
  render() {
    return (
      <div className="services__intro">
        <div className="pos-rel">
          <div className="services__intro__image">
            <img
              src="https://i.postimg.cc/rpHCR3Sd/tttt.png"
              alt=""
            />
          </div>
          <Swiper
            className="services__intro__miniSlider"
            spaceBetween={30}
            pagination={{
              clickable: true
            }}
            modules={[Pagination]}
          >
            <SwiperSlide>
              <div className="icon">
                <BiBrain />
              </div>
              <p>We are committed to delivering innovative solutions</p>
            </SwiperSlide>
            <SwiperSlide>
              <div className="icon">
                <BiBrain />
              </div>
              <p>We are committed to delivering innovative solutions.</p>
            </SwiperSlide>
          </Swiper>
        </div>
        <div>
          <div className="services__intro__text">
            <h1>At Sotechy Labs</h1>
            <p className="text">
            We turn ideas into digital innovations, providing tailored solutions for startups & businesses.
             Our mission is to craft digital excellence through cutting-edge technology and creative
              expertise.
            </p>
    
          </div>
        </div>
      </div>
    );
  }
}
export default Intro;
