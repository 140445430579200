import { Component } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";

import HotNews from "./News__Hot";
import LatestNews from "./News__Latest";
import "./style.css";

class News extends Component {
  constructor() {
    super();
    this.hotNews = [
      {
        title: "Blockchain Dev at Sotechy Labs",
        avatar:
          "https://i.postimg.cc/rFnNjgJR/1682842375869.jpg",
        text:
          "Working at Sotechy Labs has been an incredible experience. The company's commitment to innovation, creative problem-solving, and continuous learning is truly inspiring. The supportive and collaborative work environment has allowed me to grow both personally and professionally. I'm proud to be part of a team that consistently delivers digital excellence and turns client ideas into remarkable innovations.",
        author: "Soke",
        role: "Blockchain Developer"
      },
      {
        title: "Full Stack Dev at Sotechy Labs",
        avatar:
          "https://i.postimg.cc/X7z8sSFN/IMG-1249.jpg",
        text:
          "As a full-stack developer at Sotechy Labs, I've had the privilege to work on diverse and challenging projects. The company's emphasis on cutting-edge technologies, agile methodologies, and a collaborative team atmosphere has honed my skills and broadened my knowledge. It's truly fulfilling to contribute to the development of digital solutions that make a real impact in the world. Sotechy Labs is an exciting place for anyone passionate about technology and innovation",
        author: "Ik Ben",
        role: "Full Stack Developer"
      }
    ];
    this.lastestNews = [
      {
        img:
          "https://wd.imgix.net/image/0g2WvpbGRGdVs0aAPc6ObG7gkud2/yezj4CV8NRdONDSUmpWr.png",
        date: "27 Nov 2023",
        author: "Admin",
        text:
          "When browsers first reached version 10, there were a few issues as the major version number went from one digit to two. Hopefully, we learned a few things that'll ease the transition from two digits to three."
      },
      {
        img:
          "https://blog.logrocket.com/wp-content/uploads/2022/03/client-side-routing-react-location.png",
        date: "15 Dec 2023",
        author: "Admin",
        text:
          "Routing is one of the essential aspects of single-page applications (SPAs), but because React doesn’t come with a routing solution."
      }
    ];
  }
  render() {
    return (
      <section id="news" className="container news__container">
        <Swiper className="news__hot" pagination={true} modules={[Pagination]}>
          {this.hotNews.map((feed, index) => {
            return (
              <SwiperSlide>
                <HotNews
                  title={feed.title}
                  text={feed.text}
                  avatar={feed.avatar}
                  author={feed.author}
                  role={feed.role}
                  key={index}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="news__latest">
          <h1>Blog Articles</h1>
          {this.lastestNews.map((feed) => {
            return (
              <LatestNews
                img={feed.img}
                date={feed.date}
                author={feed.author}
                text={feed.text}
              />
            );
          })}
        </div>
      </section>
    );
  }
}
export default News;
