import { Component } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./style.css";
class About extends Component {
  constructor() {
    super();
    this.state = {
      percentage: 0
    };
  }
  render() {
    return (
      <section className="about__container">
        <div className="container">
          <div>
            <h1>Turning ideas into innovations</h1>
            <p className="text">
            Sotechy Labs is an innovative technology company specializing in software and web
             development, mobile app development, AI, blockchain, UX/UI design, digital marketing,
              and consulting services. 
            </p>
            <div className="about__buttons">
   
              <a href="#portfolio">
                <span>Our Portfolio</span>
                <AiOutlineArrowDown />
              </a>
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <div className="about__image">
              <img
                src="https://i.postimg.cc/pdmQYRdc/green.png"
                alt="about"
              />
            </div>
         
              <h4>
    
                <br />
                <small></small>
              </h4>
            </div>
          </div>
  
      </section>
    );
  }
}
export default About;
